'use client'
import { useState, useEffect, Fragment } from 'react'
import { useSession, signOut } from "next-auth/react"
import { PrismicNextLink, PrismicNextImage } from '@prismicio/next'
import SearchIcon from '@/assets/icons/search.svg'
import Cart from '@/assets/icons/cart-empty.svg'
import CartFull from '@/assets/icons/cart-full.svg'
import Account from '@/assets/icons/account.svg'
import RightArrow from '@/assets/icons/right-arrow.svg'
import CloseIcon from '@/assets/icons/close.svg'
import MegaMenu from '@/slices/MegaMenu'


interface MegaMenusProps {
    showBanner?: any | null
    isPanelVisible?: any | null
    alertHeight?: any | null
    megaMenus?: any | null
    menuOrder?: any | null
    theme?: any | null
    subMenu?: any | null
    cin7Menu?: any | null
    defImage?: any | null
    collections?: any | null
    lang?: any | null
    productType?: any | null
    menuOpen?: any | null
    setMenuOpen?: any | null
    megaOpen?: any | null
    setMegaOpen?: any | null
    savedCurrent?: any | null
    setSavedCurrent?: any | null
    uid?: any | null
    setAccount?: any | null
    account?: any | null
    showCart?: any | null
    setShowCart?: any | null
    countryMenu?: any | null
    setCountryMenu?: any | null
    setShowSearch?: any | null
    cartTotal?: any | null
}

const MegaMenus:React.FC<MegaMenusProps> = ({ isPanelVisible, showBanner, alertHeight, megaMenus, menuOrder, theme, subMenu, cin7Menu, defImage, lang, productType, menuOpen, setMenuOpen, megaOpen, setMegaOpen, savedCurrent, setSavedCurrent, uid, account, setAccount, showCart, setShowCart, countryMenu, setCountryMenu, setShowSearch, cartTotal}) => {
    const menuPrimary = [] as any

    menuOrder?.forEach((order: any) => {
        if(order.standard_link_text !== "") {
            menuPrimary.push({menu_data: null, link_data: { text: order.standard_link_text, link: order.standard_link }});
        }
        megaMenus?.forEach((menu: any) => {
            if(menu.id === order.mega_menu.id) {
                menuPrimary.push({menu_data: menu.data, link_data: null});
            }
        })
    });

    const { data: session, status } = useSession()

    const user = session?.user as any;

    const [isTouch, setIsTouch] = useState(false);

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    const accountShow =() => {
        document.body.style.overflow="hidden";
        setAccount(true)
    }

    const showCartMenu = ()=>{
        setShowCart(!showCart); 
        document.body.style.overflow="hidden"
    }

    const handleTouchStart = () => {
        setIsTouch(true);
    };

    useEffect(() => {
        if (menuOpen) {
            setMegaOpen(savedCurrent);
        }
    }, [menuOpen, savedCurrent]);
    
    const handleMenuItemClick = (e: any, index: number, link: any) => {
        
        const linkDestination = link?.uid ? link.uid : link?.url
        e.preventDefault();
        e.stopPropagation(); // Prevent the click event from propagating to the overlay
    
        if (isTouch) {
            if(link?.type === "page" && linkDestination !=='about-us') {
                window?.open(`/${lang}/${linkDestination}`, "_self")
            } else {
                setMegaOpen(index);
                setMenuOpen(true);
            }
            return;
        } 
        
        document.body.style.overflow = "hidden";
        window?.open(`/${lang}/${linkDestination}`, "_self")
        
    };

    const topStyle = showBanner && isPanelVisible
    ? { top: `${alertHeight + 22}px`, mdTop: '70px' }
    : { top: '23px' };

    const menuStyle = showBanner && isPanelVisible
    ? { top: `${alertHeight + 60}px`, mdTop: '70px' }
    : { top: '60px' };

    return (
        <>
            {menuOpen === true ? 
            <>
            <div className={"text-warmcharcoal fixed z-[200] left-4 right-auto md:right-8 md:left-auto lg:right-[54px] cursor-pointer hover:opacity-60 text-[14px] font-norma"} onClick={()=>{setMenuOpen(false); document.body.style.overflow="visible"}} style={topStyle}><span className="hidden md:inline">Close</span><CloseIcon className="stroke-warmcharcoal ml-2 inline-block"/></div>
            <div className="w-screen z-[190] fixed bg-warmgrey/60 bottom-0 left-0 font-norma overflow-y-scroll lg:overflow-hidden text-[14px] border-y-[1px] border-y-warmcharcoal" onClick={()=>{setMenuOpen(false); document.body.style.overflow="visible"}} style={menuStyle}>
                <div className="w-screen bg-offwhite pt-2 md:pt-0 ">
                    <ul className="w-full m-0 p-0 flex justify-end px-5 pt-0 pb-[24px] md:hidden">
                        {/* {subMenu?.map((subItem: any, index: number)=>{
                            if(subItem.link_text === "Login" && user?.uuid) {
                                subItem.link_text = "Account"
                            }
                            return (
                                <li onClick={()=>{ subItem.link_text === "Login" || subItem.link_text === "Account" ?  accountShow() : subItem.link_text === "Cart" ? showCartMenu() : null}} 
                                    className={"inline-block text-[14px] font-norma cursor-pointer relative z-2 mr-12"} key={index}>
                                        {subItem.link_text === "Login" || subItem.link_text === "Account" ? 
                                            <span className="relative">{subItem.link_text === "Account" ? <Account className={"stroke-warmcharcoal  top-[2px] relative"}/> : subItem.link_text}</span>
                                        :<></>}
                                        {subItem.link_text === "Cart" ?
                                        <span className="relative top-[3px]">{cartTotal > 0 ? <span className="relative inline-block"><CartFull className={"stroke-warmcharcoal fill-warmcharcoal"}/><span className={ "text-offwhite absolute  text-[8.5px] -top-[4.6px] left-[5px] w-[13px] text-center font-medium"}>{cartTotal}</span></span> : <Cart className={"stroke-warmcharcoal "}/>}</span>
                                        :<></>}
                                </li>                            
                            )
                        })} */}
                        <li className="text-[14px] leading-[18px] font-medium inline-block"><SearchIcon className="relative top-[6px] w-[14px] stroke-warmcharcoal" onClick={()=>{setShowSearch(true); document.body.style.overflow="hidden"}}/></li>
                    </ul>
                    <ul className="p-5 pt-2 lg:pl-[54px] w-full relative z-10 mt-4 md:mt-0 ">
                        {menuPrimary.map((primaryItem: any, index: any)=>{
                            if(primaryItem.menu_data?.section_name) return (
                                <li className={(primaryItem.link_data?.text.toLowerCase() === uid || primaryItem.link_data?.text.toLowerCase() === productType? " underline-offset-4":"")+" flex justify-between text-[14px] leading-[18px] font-medium w-full md:w-auto md:inline-block md:mr-[20px] lg:mr-[50px] cursor-pointer py-4 md:pb-0 hover:underline hover:underline-offset-4 border-b-[1px] border-b-warmcharcoal/20 md:border-none"+ (index === megaOpen ? "underline underline-offset-4" : "")} key={index} 
                                    onClick={(e:any) => handleMenuItemClick(e, index, primaryItem.link_data?.link)}
                                    onMouseEnter={() => {
                                        if (!isTouch && index !== megaOpen) {
                                            setMegaOpen(index);
                                        }
                                    }}>
                                        <span className="block">{primaryItem.menu_data.section_name}</span> 
                                        <RightArrow className="stroke-warmcharcoal rotate-180 md:hidden inline-block"/>
                                </li>
                            ) 
                            if(primaryItem.link_data?.text) return (
                                <li className={(primaryItem.link_data?.text.toLowerCase() === uid || primaryItem.link_data?.text.toLowerCase() === productType ? " underline-offset-4":"")+" flex justify-between text-[14px] leading-[18px] font-medium w-full md:w-auto md:inline-block md:mr-[20px] lg:mr-[50px] cursor-pointer py-4 md:pb-0 hover:underline hover:underline-offset-4 border-b-[1px] border-b-warmcharcoal/20 md:border-none"} key={index}>
                                    <a href={primaryItem.link_data.link?.uid ? "/"+lang+"/"+primaryItem.link_data.link.uid : primaryItem.link_data.link?.url} 
                                        onClick={(e:any) => handleMenuItemClick(e, index, primaryItem.link_data?.link)}
                                        onMouseEnter={() => {
                                            if (!isTouch && index !== megaOpen) {
                                                setMegaOpen(index);
                                            }
                                        }} 
                                        className="md:inline flex justify-between w-full">
                                        <span>{primaryItem.link_data.text}</span> 
                                        <RightArrow className="stroke-warmcharcoal rotate-180 md:hidden inline-block"/>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                    {menuPrimary.map((item: any, index: number)=>{
                        // console.log(menuPrimary)
                        if(index === megaOpen) {
                            const hasCin7Slice = megaMenus?.some(
                                (mega: any) =>
                                    mega.data?.generate_links_from_cin7 === true &&
                                    mega.uid?.toLowerCase() === item.link_data?.text?.toLowerCase()
                            );
                            return (
                                <div className={`absolute left-0 top-[60px] bg-offwhite md:top-[64px] right-0 lg:left-0 z-[60] min-h-screen md:min-h-0 border-t-[1px] border-warmcharcoal md:pb-0 ${megaOpen !== null ? 'block' : 'hidden'}`} key={index}>    
                                    <div className=" md:px-5 lg:px-[54px] w-full" key={index}>
                                            {hasCin7Slice ?
                                                <>
                                                    {cin7Menu?.map((c7item: any, c7index: number)=>{
                                                        if(c7item.producttype === item.link_data.text){
                                                        return (
                                                            <>
                                                                <h3 className="md:hidden capitalize text-[14px] font-medium m-4 border-b-[1px] border-b-warmcharcoal -mx-4 pb-4 px-8 cursor-pointer" onClick={(e:any) => handleMenuItemClick(e, -1, item.link_data.link)}><RightArrow className="stroke-warmcharcoal inline-block mr-2"/> {item.link_data.text}</h3>
                                                                <div className="grid grid-cols-12 w-full pb-4" key={c7index}>
                                                                {c7item.productsubtypes?.map((sub: any, mindex: number)=>{
                                                                    return (
                                                                        <div className="col-span-12 md:col-span-3 lg:col-span-2 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0" key={mindex}>
                                                                            <p className={"inline-block py-2 pb-5 md:pb-0 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 "} key={mindex}>
                                                                                <a className={sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---") === uid ? " underline underline-offset-4 font-medium" : ""} href={"/"+lang+"/"+c7item.producttype?.trim().toLowerCase().replace(/ /g, "-")+"/"+sub?.trim().toLowerCase().replace(/ /g, "-").replace("-&-", "---").replace("-+-", "---")}>{sub.replace("&", "+")}</a>
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })}
                                                                </div>
                                                            </>
                                                        )}
                                                    })}
                                                </>
                                                :
                                                <>
                                                {megaMenus?.map((mega: any, megaIndex: number) => {
                                                    if (mega.uid.toLowerCase() === menuPrimary[megaOpen]?.link_data?.text?.toLowerCase()) {
                                                        return (
                                                            <>
                                                            <h3 className="md:hidden capitalize text-[14px] font-medium m-4 border-b-[1px] border-b-warmcharcoal -mx-4 pb-4 px-8 cursor-pointer" onClick={(e:any) => handleMenuItemClick(e, -1, item.link_data.link)}><RightArrow className="stroke-warmcharcoal inline-block mr-2"/> {mega.uid}</h3>
                                                            <div className="grid grid-cols-12 w-full pb-4" key={megaIndex}>
                                                                {mega.data?.slices?.length
                                                                    ? mega.data.slices.map((slice: any, sindex: number) => (
                                                                        <Fragment key={sindex}>
                                                                            {slice.items.map((item: any, itemIndex: number) => (
                                                                                <div className="col-span-12 md:col-span-3 lg:col-span-2 border-b-[1px] border-b-warmcharcoal/20 md:border-none px-4 md:px-0" key={itemIndex}>
                                                                                    <p
                                                                                        className={`capitalize inline-block py-2 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4`}
                                                                                    >
                                                                                        <a
                                                                                            className={`${
                                                                                                item.collection_page?.uid === uid
                                                                                                    ? ' underline underline-offset-4 font-medium '
                                                                                                    : ''
                                                                                            }`} href={`/${lang}/collections/${slice.primary.collection_section?.uid}/${item.collection_page?.uid}`} onClick={()=>{setMegaOpen(index)}}
                                                                                        >
                                                                                            {item.collection_page?.uid
                                                                                                ?.replace(/---/g, '-+-')
                                                                                                .replace(/-/g, ' ')}
                                                                                        </a>
                                                                                    </p>
                                                                                </div>
                                                                            ))}
                                                                        </Fragment>
                                                                    ))
                                                                    : mega.data.links?.map((page: any, pageIndex: number) => (
                                                                        <div className="col-span-12 md:col-span-3 lg:col-span-2" key={pageIndex}>
                                                                            <p
                                                                                className={`capitalize inline-block py-2 text-[14px] leading-[18px] cursor-pointer font-normal mt-3 hover:underline hover:underline-offset-4 ${
                                                                                    page.link_text?.trim().toLowerCase().replace(/ /g, '-').replace(/-&-/, '-') ===
                                                                                    uid
                                                                                        ? ' underline underline-offset-4 font-medium '
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                <PrismicNextLink field={page.link} onClick={()=>{setMegaOpen(index)}}>{page.link_text}</PrismicNextLink>
                                                                            </p>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>}
                                        </div>
                                        
                                    </div>
                            )
                        }
                        return null
                    })}
                </div>
            </div>
            </>
        :<></>}
        </>
    )
}

export default MegaMenus
