'use client'; // Ensure this is at the top of the file for Next.js if using app-router

import { useEffect, useState } from 'react';
import { getCountryData } from '@/components/getCountryData';
import PayNow from '@/assets/icons/Afterpay_PayNow_Button_WhiteMint-Black.svg'
import NextLink from 'next/link';


interface AfterPayPaymentProps {
    total?: number | null;
    lang?: any | null;
    orderData?: any | null;
}

const AfterPayPayment: React.FC<AfterPayPaymentProps> = ({ lang, total, orderData }) => {

    // console.log(orderData)
    const { currency, priceColumn } = getCountryData(lang);

    const [ afterpayToken, setAfterPayToken ] = useState(null) as any    
    
    useEffect(()=>{
        const getLink = async () =>{
            const afterPayRequest = await fetch('/api/payments/afterpay', {method: "post", body: JSON.stringify(orderData)})
            const afterPayResult = await afterPayRequest.json();
            setAfterPayToken(afterPayResult)
        }

        getLink()

    }, [orderData, total])
    
    return (
        <div>
            {afterpayToken?.redirectUrl ? 
            <div id="afterpay-express-button" className="text-center font-norma font-medium text-[16px]">
                <p className="pt-12 pb-4 font-norma text-[16px] font-medium text-center">Pay {currency} {Number(total+ orderData.order.freightTotal)?.toFixed(2)}</p>
                <NextLink href={afterpayToken.redirectUrl}>
                    <PayNow className="w-full h-[50px] object-contain mex-auto block cursor-pointer hover:opacity-60"/>
                </NextLink>
            </div>
            :<></>}
        </div>
    );
};

export default AfterPayPayment;
