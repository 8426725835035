    'use client';

    import { useEffect, useState, useRef } from 'react';
    import { PrismicRichText } from '@prismicio/react';
    import Close from '@/assets/icons/close.svg';
    import Moment from 'moment';

    interface AlertPanelProps {
        isPanelVisible?: any | null
        setIsPanelVisible?: any | null
        setAlertHeight?: any | null
        lang?: any | null;
        menus?: any | null;
        removeAlertPanel?: any | null;
        uid?: any | null;
        setAlertPanel?: any | null;
        local?: any | null;
    }

    const AlertPanel: React.FC<AlertPanelProps> = ({ isPanelVisible, setIsPanelVisible, setAlertHeight, lang, menus, removeAlertPanel, uid, setAlertPanel, local }) => {
    const [hideClose, setHideClose] = useState(false);
    
    const alertRef = useRef(null) as any

    useEffect(() => {
        setAlertHeight(alertRef?.current.offsetHeight)
        if (menus?.data.banner_alert) {
            menus.data.banner_alert.forEach((item: any) => {
                switch (item.visibility) {
                case 'Home Page Only - removable':
                    !uid ? setIsPanelVisible(true) : setIsPanelVisible(false);
                    setHideClose(false);
                    break;
                case 'Home Page Only - permanent':
                    !uid ? setIsPanelVisible(true) : setIsPanelVisible(false);
                    setHideClose(true);
                    break;
                case 'All Pages Only - removable':
                    setIsPanelVisible(true);
                    setHideClose(false);
                    break;
                case 'All Pages - permanent':
                    setIsPanelVisible(true);
                    setHideClose(false);
                    break;
                default:
                    setIsPanelVisible(false);
                }
            });
        }
    }, [menus, uid, setAlertPanel, alertRef]);

    // if (!local?.time || local?.time < Moment().format('YYYY-MM-DD')) {
        return (
        <>
            {isPanelVisible &&
            menus?.data.banner_alert?.map((item: any, index: number) => {
                if (lang.toLowerCase() === item?.region?.toLowerCase() && item?.visible === true) {
                return (
                    <section
                    className="bg-warmcharcoal w-screen px-12 md:h-[48px] text-offwhite font-norma text-[14px] text-center p-3"
                    key={index}
                    ref={alertRef}
                    id="alertBanner"
                    >
                    <PrismicRichText field={item?.alert_text} />
                    {!hideClose ? (
                        <Close
                        className="stroke-offwhite absolute right-4 lg:right-[50px] top-4 cursor-pointer hover:opacity-60"
                        onClick={() => removeAlertPanel(true)}
                        />
                    ) : null}
                    </section>
                );
                }
                return null;
            })}
        </>
        );
    // }
    return null;
    };

    export default AlertPanel;
