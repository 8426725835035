'use client'; // Ensure this is at the top of the file for Next.js if using app-router

import Script from 'next/script';

interface AfterPayPromoProps {
    amount?: any | null;
    currency?: any | null;
    lang?: any | null;
    cart?: any | null
}

const AfterPayPromo: React.FC<AfterPayPromoProps> = ({ amount, currency, lang, cart }) => {

    const MPID = lang === "nz" ? process.env.NEXT_PUBLIC_AFTERPAY_MPID_NZ : lang === "au" ? process.env.NEXT_PUBLIC_AFTERPAY_MPID_AU : "" as any
    let PID = lang === "nz" ? process.env.NEXT_PUBLIC_AFTERPAY_PID_NZ : lang === "au" ? process.env.NEXT_PUBLIC_AFTERPAY_PID_AU : "" as any
        
    if(cart === true ) {
        PID = lang === "nz" ? process.env.NEXT_PUBLIC_AFTERPAY_CART_PID_NZ : lang === "au" ? process.env.NEXT_PUBLIC_AFTERPAY_CART_PID_AU : "" as any
    }

    if(lang === "nz" || lang ==="au") {
            return (
            <>
                <Script
                    id="afterPayScript"
                    src="https://js.squarecdn.com/square-marketplace.js"
                    strategy="afterInteractive"
                />
                <square-placement
                    data-mpid={MPID}
                    data-placement-id={PID}
                    data-page-type={cart === true ? "cart" : "product"}
                    data-amount={amount}
                    data-currency={currency}
                    data-consumer-locale={lang}
                    data-is-eligible="true"
                >
                </square-placement>
            </>
        );
    }
    return <></>
    }

export default AfterPayPromo;
