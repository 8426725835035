'use client';

import { useWindowScroll } from 'react-use';

interface MegaMenuButtonProps {
    alertHeight?: any | null
    alertPanel?: any | null;
    menuOpen?: any | null;
    setMenuOpen?: any | null;
    theme?: any | null;
    megaOpen?: any | null;
    setMegaOpen?: any | null;
    local?: any | null;
    chrome?: any | null
}

const MegaMenuButton: React.FC<MegaMenuButtonProps> = ({ alertHeight, menuOpen, setMenuOpen, theme, setMegaOpen, alertPanel, local, chrome }) => {
    const { y } = useWindowScroll();

    const topStyle = alertPanel
    ? { top: `${alertHeight + 22}px` }
    : { top: '23px' };


    let testTheme = theme;
    if (y > 100) {
        testTheme = false;
    }

    const combinedClassName = `${alertPanel ? "md:top-[70px]" : ""} fixed left-4 md:left-[calc(100vw_-_120px)] md:right-[54px] cursor-pointer z-[201] md:w-[120px]`;

    return (
        <div
            className={combinedClassName}
            style={topStyle} 
            onClick={() => {
                setMenuOpen(!menuOpen);
                document.body.style.overflow = "hidden";
            }}
        >
            {menuOpen === false ? (
                <div className={`${testTheme === true ? "text-offwhite" : "text-warmcharcoal"} font-norma text-[14px] text-right md:pr-[54px]`}>
                    <span className={`${testTheme === true ? "border-y-offwhite" : "border-y-warmcharcoal"} " inline-block w-[36px] h-[10px]  border-y-[1px] md:hidden`}></span>
                    <span className="hidden md:inline-block">Shop now</span>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default MegaMenuButton;

